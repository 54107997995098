import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { ReactNode } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { GlobalToastRegion } from '@pledge-earth/product-language';

import { ReduxSagaProvider } from '../store/provider';
import { reducer as settings } from '../store/settings/reducers';
import { Localization } from '../localization';
import { GoogleMapsAPIProvider } from '../components/Map/GoogleMapsAPIProvider';

import { FlagProvider } from './FlagProvider';

const reducer = combineReducers({
  settings,
});

const store = configureStore({
  reducer,
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT,
  cache: new InMemoryCache(),
});

export function ProviderShare({ children }: { children: ReactNode }) {
  return (
    <ApolloProvider client={client}>
      <ReduxSagaProvider store={store}>
        <FlagProvider isAnonymous={true}>
          <GoogleMapsAPIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY || ''}>
            <Localization>
              {children}
              <GlobalToastRegion />
            </Localization>
          </GoogleMapsAPIProvider>
        </FlagProvider>
      </ReduxSagaProvider>
    </ApolloProvider>
  );
}
